// REACT AND GATSBY IMPORTS
import React from 'react';
// STYLING
// CONTAINERS
// COMPONENTS (landing-gatsby)
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
// COMMON COMPONENTS
import UserList from 'common/src/components/UserList'

// ADMIN INTERFACE PAGE TEMPLATE
const AdminTemplatePage = () => {
  return (
    <UsersLayout seo={{ title: 'Admin Template Page' }}>
      <UserList title="User List"/>
    </UsersLayout>
  );
};

export default AdminTemplatePage;
